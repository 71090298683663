* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html, h1, h2, h3, ol, li {
  margin: 0;
  padding: 0;
}

body {
  font-weight: 400;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  font-family: weissenhof_grotesk, sans-serif;
  padding-top: 1rem;
  padding-bottom: 4rem;
}

h1, h2 {
  font-size: 1.4rem;
  font-weight: 500;
}

a, a:visited {
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}
